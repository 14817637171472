<template>
  <ElDialog :visible.sync="visible" title="Stocuri">
    <template v-if="visible">
       <ElTable :data="quantityTotalsAsRows" :row-class-name="getRowClassName">
        <ElTableColumn label="Material">
          <template slot-scope="{ row }">
            <div v-if="materialsById[row.id] && materialsById[row.id].data">
            <!-- verificare -pentru cazult in care se sterge un material - e bai mare aici -->
              <span>
                <span >{{ materialsById[row.id].data.name }}</span>
                <span class="ml-5">
                  <ElButton
                    size="mini"
                    icon="el-icon-plus"
                    @click="$refs.materialOrderDialog.add(row.id)"
                    >Plaseaza</ElButton
                  >
                </span>
              </span>

              <span>
                <el-alert
                  class="mt-2"
                  show-icon
                  v-if="row.quantity > materialsById[row.id].data.currentQuantity"
                  title="Stoc insuficient!"
                  type="error"
                ></el-alert>
              </span>
            </div>
            <div v-else>Materialul cu (id {{row.id}}) a fost sters</div>
          </template>
        </ElTableColumn>

        <ElTableColumn label="Necesar" width="100" align="right">
          <template slot-scope="{ row }">
            {{ row.quantity }}
          </template>
        </ElTableColumn>

        <ElTableColumn label="Existent" width="100" align="right">
          <template slot-scope="{ row }">
            <span v-if="materialsById[row.id] && materialsById[row.id].data">{{ materialsById[row.id].data.currentQuantity | number }}</span>
            <span v-else>-</span>
          </template>
        </ElTableColumn>
      </ElTable>
    </template>
    <MaterialOrderDialog ref="materialOrderDialog" />
  </ElDialog>
</template>

<script>
import { getMaterialQuantityTotals } from "@/calculator";
import MaterialOrderDialog from "@/components/materials/MaterialOrderDialog";

export default {
  props: ["project", "context", "materialsById"],
  components: {
    MaterialOrderDialog
  },
  data() {
    return {
      visible: false
    };
  },
  computed: {
    quantityTotals() {
      return getMaterialQuantityTotals(
        Object.values(this.project.tasks),
        this.context
      );
    },
    quantityTotalsAsRows() {
      return Object.entries(this.quantityTotals).map(([id, quantity]) => ({
        id,
        quantity
      }));
    }
  },
  methods: {
    open() {
      this.visible = true;
    },
    getRowClassName({ row }) {
      //raul hf daca stergi materiale- anrei nu a gandit cazul in totalitate
      if(this.materialsById[row.id] && this.materialsById[row.id].data) {
        if (this.materialsById[row.id].data.currentQuantity < row.quantity) {
          return "bg-red-200";
        }
      }
    }
  }
};
</script>
