<template>
  <ElDialog :visible.sync="visible" title="Activitati" width="70%">
    <template v-if="visible">
      <ElTable :data="activities">
        <ElTableColumn prop="data.description" label="Descriere" width="300" />
        <ElTableColumn label="Angajat">
          <template slot-scope="{ row }" v-if="employees">
            <span
              v-for="employeeId in row.data.employeeIds"
              :key="employeeId"
              >{{ employeesById[employeeId].data.name }}</span
            >
          </template>
        </ElTableColumn>
        <ElTableColumn prop="data.createdAt" label="Data">
          <TimeAgo
            slot-scope="{ row }"
            v-if="row.data.createdAt"
            :date="row.data.createdAt.toDate()"
          />
        </ElTableColumn>
      </ElTable>
    </template>
  </ElDialog>
</template>

<script>
import { keyBy } from "lodash";

export default {
  props: ["projectId"],
  data() {
    return {
      visible: false,
      employees: null,
      activities: null
    };
  },
  methods: {
    async open() {
      this.visible = true;

      this.employees = await this.$fetch(
        this.$firestore().collection("employees")
      );

      this.activities = await this.$fetch(
        this.$firestore()
          .collection("activities")
          .orderBy("createdAt", "desc")
          .where("projectId", "==", this.projectId)
      );
    }
  },
  computed: {
    employeesById() {
      return keyBy(this.employees, "id");
    }
  }
};
</script>
