<template>
  <div
    class="bg-white p-5 overflow-y-auto flex-shrink-0 border-r border-gray-400"
    style="width: 400px;"
  >
    <div v-if="hideName" class="text-lg font-bold mb-5">
      {{ componentData.name }}
    </div>
    <div v-else class="text-lg font-bold mb-5">Componenta</div>
    <!-- name -->
    <div v-if="!hideName" class="flex items-center mb-2">
      <label class="block w-24">Nume</label>
      <ElInput v-model="componentData.name" class="flex-1" />
    </div>
    <!-- sheet -->
    <div class="flex items-center mb-2">
      <label class="block w-24">Hartie</label>
      <ElSelect
        clearable
        filterable
        v-model="request.sheetCategoryId"
        @change="sheetCategoryChanged"
        class="flex-1"
        placeholder="Categorie"
      >
        <ElOption
          v-for="category in materialCategories"
          :key="category.id"
          :value="category.id"
          :label="category.data.name"
        />
      </ElSelect>
    </div>
    <!-- weight -->
    <div class="flex items-center mb-2">
      <label class="block w-24">Grosime</label>
      <ElSelect
        v-model="request.sheetWeight"
        :disabled="!sheetWeightOptions"
        @change="sheetWeightChanged"
        class="flex-1"
        filterable
        placeholder="Grosime"
      >
        <ElOption
          v-for="weight in sheetWeightOptions"
          :value="weight"
          :label="`${weight}g`"
          :key="weight"
        />
      </ElSelect>
    </div>
    <!-- colors -->
    <div class="flex items-center mb-2">
      <label class="block w-24">Culori</label>
      <ElInput
        v-model.number="request.frontColors"
        @change="printingColorsChanged"
        class="flex-1"
        type="number"
      />
      <div class="w-8 text-center">+</div>
      <ElInput
        v-model.number="request.backColors"
        @change="printingColorsChanged"
        class="flex-1"
        type="number"
      />
    </div>
    <!-- pages -->
    <div class="flex items-center mb-2">
      <label class="block w-24">Pagini</label>
      <ElInput v-model="request.pages" class="flex-1" />
      <div class="w-8 text-center">x</div>
      <ElInput
        v-model.number="request.copies"
        class="flex-1"
        type="number"
        min="1"
      />
    </div>
    <!-- extra -->
    <div class="flex items-center h-10">
      <label class="block w-24">Extra</label>
      <ElCheckbox v-model="request.varnish">Lacuire</ElCheckbox>
      <ElCheckbox v-model="request.lamination">Foliere</ElCheckbox>
      <!-- <ElCheckbox v-model="request.creasing">Biguire</ElCheckbox> -->
    </div>
    <ElButton @click="$emit('regenerate')">Regenerare</ElButton>
    <ElButton class="mt-5" @click="advancedDialogVisible = true"
      >Avansat</ElButton
    >
    <ElButton class="mt-5" @click="diagramDialogVisible = true"
      >Diagrame</ElButton
    >
    <!-- dialogs -->
    <ElDialog
      title="Avansat"
      :visible.sync="advancedDialogVisible"
      custom-class="w-auto m-5"
    >
      <!-- width -->
      <div class="flex items-center mb-2">
        <label class="block w-32">Latime</label>
        <ElInput v-model="request.width" class="w-64" />
        <div class="ml-2">{{ evaluate(request.width) }}</div>
      </div>
      <!-- height -->
      <div class="flex items-center mb-2">
        <label class="block w-32">Inaltime</label>
        <ElInput v-model="request.height" class="w-64" />
        <div class="ml-2">{{ evaluate(request.height) }}</div>
      </div>
      <!-- large sheets -->
      <div v-if="largeSheetOptions" class="flex items-center mb-2">
        <label class="block w-32">Coli Mari</label>
        <ElCheckboxGroup v-model="request.largeSheetIds" class="flex-1">
          <ElCheckbox
            v-for="largeSheet in largeSheetOptions"
            :key="largeSheet.id"
            :label="largeSheet.id"
            border
            class="mr-0"
          >
            {{ largeSheet.data.width }} x {{ largeSheet.data.height }} ({{
              largeSheet.data.currentQuantity
            }})
          </ElCheckbox>
        </ElCheckboxGroup>
      </div>
      <!-- printing presses -->
      <div class="flex items-center mb-2">
        <label class="block w-32">Prese Tipar</label>
        <ElCheckboxGroup v-model="request.printerIds" class="flex-1">
          <ElCheckbox
            v-for="printer in printerOptions"
            :key="printer.id"
            :label="printer.id"
            border
            class="mr-0"
            >{{ printer.data.name }}</ElCheckbox
          >
        </ElCheckboxGroup>
      </div>
      <!-- final sheet layouts -->
      <div class="flex items-center mb-2">
        <label class="block w-32">Asezare pagini</label>
        <ElCheckboxGroup v-model="request.finalSheetLayouts" class="flex-1">
          <div class="mb-2">
            <ElCheckbox border class="mr-0" label="1x1" />
            <ElCheckbox border class="mr-0" label="2x1" />
            <ElCheckbox border class="mr-0" label="3x1" />
            <ElCheckbox border class="mr-0" label="4x1" />
          </div>
          <div>
            <ElCheckbox border class="mr-0" label="2x2" />
            <ElCheckbox border class="mr-0" label="2x3" />
            <ElCheckbox border class="mr-0" label="2x4" />
            <ElCheckbox border class="mr-0" label="4x2" />
          </div>
        </ElCheckboxGroup>
      </div>
      <!-- tasks -->
      <hr class="mb-2" />
      <div class="flex items-center">
        <label class="block w-32">Sarcini</label>
        <div class="flex-1">
          <div class="mb-2">
            <ElCheckbox border class="mr-0" v-model="request.printing"
              >Tiparire</ElCheckbox
            >
          </div>
          <div class="mb-2">
            <ElCheckbox border class="mr-0" v-model="request.largeSheetCutting"
              >Formatizare 1</ElCheckbox
            >
            <ElCheckbox border class="mr-0" v-model="request.printSheetCutting"
              >Formatizare 2</ElCheckbox
            >
            <ElCheckbox border class="mr-0" v-model="request.simpleSheetCutting"
              >Formatizare Simpla</ElCheckbox
            >
          </div>
          <div class="mb-2">
            <ElCheckbox border class="mr-0" v-model="request.folding"
              >Faltuire</ElCheckbox
            >
            <ElCheckbox border class="mr-0" v-model="request.manualCollation"
              >Adunare Manual</ElCheckbox
            >
            <ElCheckbox border class="mr-0" v-model="request.sewing"
              >Coasere</ElCheckbox
            >
            <!-- <ElCheckbox border class="mr-0" v-model="request.creasing">Biguire</ElCheckbox> -->
          </div>
          <div class="mb-">
            <ElCheckbox border class="mr-0" v-model="request.plates"
              >Executare Matrite</ElCheckbox
            >
            <ElCheckbox border class="mr-0" v-model="request.platesStorage"
              >Conservare Matrite</ElCheckbox
            >
          </div>
        </div>
      </div>
    </ElDialog>
    <ElDialog title="Diagrame" :visible.sync="diagramDialogVisible">
      <div v-for="(imposition, index) in impositions" :key="index">
        <div v-if="printersById[imposition.printerId]" class="mb-2">
          {{ printersById[imposition.printerId].data.name }}
          ({{ imposition.distinctPages }} pagini{{
            imposition.shouldTurn ? " - intors" : ""
          }})
        </div>
        <div v-else>
          <!-- adaugat de Raul -->
          Eroare inconsistenta date.
        </div>
        <ImpositionVisualizer
          label="Label"
          :imposition="imposition"
          :printersById="printersById"
        />
      </div>
    </ElDialog>
  </div>
</template>

<script>
import { getAppropriatePrinters, getAppropriateLargeSheets } from "@/calculator"
import { uniqBy } from "lodash"
import ImpositionVisualizer from "@/components/utils/ImpositionVisualizer"

export default {
  props: [
    "componentData",
    "evaluate",
    "printers",
    "printersById",
    "largeSheets",
    "materialCategories",
    "hideName",
  ],
  components: {
    ImpositionVisualizer,
  },
  data() {
    return {
      advancedDialogVisible: false,
      diagramDialogVisible: false,
    }
  },
  created() {
    if (this.request.copies === undefined) {
      this.$set(this.request, "copies", 1)
    }
  },
  computed: {
    request() {
      return this.componentData.request
    },
    impositions() {
      const serialize = (object) => JSON.stringify(object)
      return uniqBy(this.componentData.impositions, serialize)
    },
    sheetWeightOptions() {
      const { sheetCategoryId } = this.request

      if (sheetCategoryId === null) return null

      return this.largeSheets
        .filter((sheet) => sheet.data.categoryId === sheetCategoryId)
        .map((sheet) => sheet.data.weight)
        .filter((sheet, index, self) => self.indexOf(sheet) === index)
        .sort((a, b) => a - b)
    },
    largeSheetOptions() {
      return getAppropriateLargeSheets(this.request, this.largeSheets)
    },
    printerOptions() {
      return getAppropriatePrinters(this.request, this.printers)
    },
  },
  methods: {
    sheetCategoryChanged() {
      this.request.sheetWeight = null
      this.request.largeSheetIds = null
    },
    sheetWeightChanged() {
      this.request.largeSheetIds = this.largeSheetOptions.map(
        (sheet) => sheet.id
      )
    },
    printingColorsChanged() {
      this.request.printerIds = this.printerOptions.map((printer) => printer.id)
    },
  },
}
</script>
