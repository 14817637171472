<template>
  <ElDialog :visible.sync="visible" title="Formate" custom-class="max-w-xl">
    <template v-for="(format, index) in formats">
      <div class="flex items-center mb-2" :key="index">
        <div class="block w-16">{{ format[0] }}</div>
        <div class="block w-32">{{ format[1] }} x {{ format[2] }} mm</div>
        <ElButton class="flex-1" @click.native="select(format[1], format[2])">
          Portrait
        </ElButton>
        <ElButton class="flex-1" @click.native="select(format[2], format[1])">
          Landscape
        </ElButton>
      </div>
    </template>
  </ElDialog>
</template>

<script>
export default {
  data() {
    return { visible: false }
  },
  computed: {
    formats() {
      return [
        ["A3", 297, 420],
        ["A4", 210, 297],
        ["A5", 148, 210],
        ["A6", 105, 148],
        ["B4", 250, 353],
        ["B5", 176, 250],
        ["B6", 125, 176],
        ["F5", 99, 210],
        [null, 210, 210],
      ]
    },
  },
  methods: {
    open() {
      this.visible = true
    },
    select(width, height) {
      this.$emit("select", { width, height })
      this.visible = false
    },
  },
}
</script>
