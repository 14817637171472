<template>
  <ElDialog :visible.sync="visible" title="Sabloane">
    <ElTabs>
      <ElTabPane style="height:60vh;" label="Lista">
        <ResourceSelect
          v-model="templateIdFilter"
          collection-name="projectTemplates"
          placeholder="Filtreaza dupa nume"
          class="mb-2"
        />
        <ElTable style="height:50vh; overflow-y: scroll;" :data="filteredTemplates" :show-header="false">
          <ElTableColumn type="index" />
          <ElTableColumn prop="data.name" />
          <ElTableColumn>
            <template slot-scope="{ row }" v-if="row.data.clientId">
              {{ clientsById[row.data.clientId].data.name }}
            </template>
          </ElTableColumn>
          <ElTableColumn align="right">
            <template slot-scope="{ row }">
              <ElButton @click="selectTemplate(row)" size="mini"
                >Incarca</ElButton
              >
              <ElButton
                @click="deleteTemplate(row)"
                icon="el-icon-delete"
                size="mini"
              ></ElButton>
            </template>
          </ElTableColumn>
        </ElTable>
      </ElTabPane>
      <ElTabPane label="Adaugare">
        <div class="flex mb-5">
          <ElInput
            v-model="name"
            class="flex-1 mr-2"
            placeholder="Nume Sablon"
          />
          <ResourceSelect
            v-model="clientId"
            collection-name="clients"
            placeholder="Client"
            class="mr-2"
          />
          <ElButton @click="saveTemplate" :disabled="!name"
            >Sablon Nou</ElButton
          >
        </div>
      </ElTabPane>
    </ElTabs>
  </ElDialog>
</template>

<script>
import { keyBy, mapValues, cloneDeep } from "lodash";
import { taskInstanceBase } from "@/calculator";

export default {
  props: ["project"],
  data() {
    return {
      visible: false,
      clients: null,
      templates: null,
      name: null,
      clientId: null,
      templateIdFilter: null
    };
  },
  computed: {
    clientsById() {
      return keyBy(this.clients, "id");
    },
    filteredTemplates() {
      return this.templateIdFilter
        ? this.templates.filter(
            template => template.id === this.templateIdFilter
          )
        : this.templates;
    }
  },
  created() {
    this.$bind("clients", this.$firestore().collection("clients"));
    this.$bind("templates", this.$firestore().collection("projectTemplates"));
  },
  methods: {
    open() {
      this.visible = true;
    },
    saveTemplate() {
      const { name, clientId } = this;
      const { components, edges, variables } = this.project;
      const tasks = mapValues(this.project.tasks, task => ({
        ...task,
        ...taskInstanceBase
      }));

      this.$firestore()
        .collection("projectTemplates")
        .add({
          name,
          clientId,
          tasks,
          components,
          edges,
          variables,
          createdAt: this.$firestore.FieldValue.serverTimestamp()
        });

      this.name = null;
      this.clientId = null;
    },
    selectTemplate(template) {
      this.$emit("select", cloneDeep(template.data));
      this.visible = false;
    },
    deleteTemplate(template) {
      this.$firestore()
        .collection("projectTemplates")
        .doc(template.id)
        .delete();
    }
  }
};
</script>
