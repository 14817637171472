<template>
  <div
    class="bg-white p-5 overflow-y-auto flex-shrink-0 border-r border-gray-400"
    style="width: 400px;"
  >
    <div class="text-lg font-bold mb-5">Sarcina</div>
    <!-- name -->
    <div class="flex items-center mb-2">
      <label class="block w-24">Nume</label>
      <ElInput v-model="taskData.name" class="flex-1" />
    </div>
    <!-- alert -->
    <div class="flex items-center mb-2">
      <label class="block w-24">Alerta</label>
      <ElInput v-model="taskData.alert" class="flex-1" />
    </div>
    <!-- image -->
    <div class="flex items-center mb-2">
      <label class="block w-24">Imagine</label>
      <div class="flex-1">
        <a :href="taskData.imageUrl" target="_blank" v-if="taskData.imageUrl">
          <img
            class="max-w-full"
            style="max-height: 200px"
            :src="taskData.imageUrl"
          />
        </a>
        <ElButton v-if="taskData.imageUrl" @click="removeImage" class="mt-2"
          >Sterge Imaginea</ElButton
        >
        <ElButton v-else class="relative">
          Ataseaza Imaginea
          <input
            class="absolute opacity-0 top-0 left-0 w-full h-full cursor-pointer"
            type="file"
            accept="image/*"
            ref="image"
            @change="uploadImage"
          />
        </ElButton>
      </div>
    </div>
    <!-- operations -->
    <div>
      <b>Operatiuni</b>
      <ElButton
        @click="addOperation"
        icon="el-icon-plus"
        type="text"
        class="ml-2"
      />
    </div>
    <div>
      <div
        class="flex items-center"
        v-for="(taskOperation, index) in taskData.operations"
        :key="index"
      >
        <div class="flex-1" v-if="taskOperation.operationId">
          {{ operationsById[taskOperation.operationId].data.name }}
          <template
            v-if="operationsById[taskOperation.operationId].data.parameterName"
          >
            {{ evaluate(taskOperation.parameter) }}
            {{ operationsById[taskOperation.operationId].data.parameterName }}
          </template>
        </div>
        <div class="flex-1" v-else>???</div>
        <div>{{ evaluate(taskOperation.quantity) }}</div>
        <ElButton
          @click="editOperation(taskOperation)"
          icon="el-icon-edit"
          type="text"
          class="ml-2"
        />
      </div>
    </div>
    <!-- materials -->
    <div>
      <b>Materiale</b>
      <ElButton
        @click="addMaterial"
        icon="el-icon-plus"
        type="text"
        class="ml-2"
      />
    </div>
    <div>
      <div
        class="flex items-center"
        v-for="(taskMaterial, index) in taskData.materials"
        :key="index"
      >
        <div class="flex-1" v-if="taskMaterial.materialId">
          <span v-if="materialsById[taskMaterial.materialId]">
            {{ materialsById[taskMaterial.materialId].data.name }}
          </span>
          <span v-else class="text-red-500">
            Materialul a fost sters din lista
          </span>
        </div>
        <div class="flex-1" v-else>???</div>
        <div>{{ evaluate(taskMaterial.quantity) }}</div>
        <ElButton
          @click="editMaterial(taskMaterial)"
          icon="el-icon-edit"
          type="text"
          class="ml-2"
        />
      </div>
    </div>
    <!-- dialogs -->
    <ElDialog
      title="Operatiune"
      :visible.sync="operationDialogVisible"
      @close="syncOperationName"
    >
      <template v-if="selectedTaskOperation">
        <div class="flex items-center mb-2">
          <div class="w-20">Nume</div>
          <ElSelect
            class="flex-1"
            v-model="selectedTaskOperation.operationId"
            filterable
            default-first-option
          >
            <ElOption
              v-for="operation in operationsById"
              :key="operation.id"
              :value="operation.id"
              :label="operation.data.name"
            />
          </ElSelect>
          <div class="w-16 pl-2"></div>
        </div>
        <div
          class="flex items-center mb-2"
          v-if="selectedTaskOperation.operationId"
        >
          <div class="w-20">
            {{
              getParameterName(selectedTaskOperation.operationId) | capitalize
            }}
          </div>
          <ElInput class="flex-1" v-model="selectedTaskOperation.parameter" />
          <div class="w-16 pl-2">
            {{ evaluate(selectedTaskOperation.parameter) }}
          </div>
        </div>
        <div class="flex items-center mb-2">
          <div class="w-20">Cantitate</div>
          <ElInput class="flex-1" v-model="selectedTaskOperation.quantity" />
          <div class="w-16 pl-2">
            {{ evaluate(selectedTaskOperation.quantity) }}
          </div>
        </div>
        <div class="flex items-center mb-2 h-10">
          <div class="w-20">Pret</div>
          <div>
            {{ getTaskOperationPrice(selectedTaskOperation) | number }}
            lei
          </div>
        </div>
        <ElButton
          @click="removeOperation(selectedTaskOperation)"
          icon="el-icon-delete"
        />
      </template>
    </ElDialog>
    <ElDialog title="Material" :visible.sync="materialDialogVisible">
      <template v-if="selectedTaskMaterial">
        <div class="flex items-center mb-2">
          <div class="w-20">Nume</div>
          <ElSelect
            class="flex-1"
            v-model="selectedTaskMaterial.materialId"
            filterable
            default-first-option
          >
            <ElOption
              v-for="material in materialsById"
              :key="material.id"
              :value="material.id"
              :label="material.data.name"
            />
          </ElSelect>
          <div class="w-16 pl-2"></div>
        </div>
        <div class="flex items-center mb-2">
          <div class="w-20">Cantitate</div>
          <ElInput class="flex-1" v-model="selectedTaskMaterial.quantity" />
          <div class="w-16 pl-2">
            {{ evaluate(selectedTaskMaterial.quantity) }}
          </div>
        </div>
        <div class="flex items-center mb-2 h-10">
          <div class="w-20">Pret</div>
          <div>
            {{ getTaskMaterialPrice(selectedTaskMaterial) | number }}
            lei
          </div>
        </div>
        <ElButton
          @click="removeMaterial(selectedTaskMaterial)"
          icon="el-icon-delete"
        />
      </template>
    </ElDialog>
  </div>
</template>

<script>
import { getTaskOperationPrice, getTaskMaterialPrice } from "@/calculator";

export default {
  props: ["taskData", "evaluate", "operationsById", "materialsById", "context"],
  data() {
    return {
      operationDialogVisible: false,
      selectedTaskOperation: null,
      materialDialogVisible: false,
      selectedTaskMaterial: null
    };
  },
  methods: {
    addOperation() {
      const taskOperation = {
        operationId: null,
        quantity: "quantity",
        parameter: "1"
      };
      this.taskData.operations.push(taskOperation);
      this.editOperation(taskOperation);
    },
    removeOperation(taskOperation) {
      this.taskData.operations = this.taskData.operations.filter(
        s => s !== taskOperation
      );
      this.operationDialogVisible = false;
    },
    editOperation(taskOperation) {
      this.operationDialogVisible = true;
      this.selectedTaskOperation = taskOperation;
    },
    addMaterial() {
      const taskMaterial = {
        materialId: null,
        quantity: "quantity"
      };
      this.taskData.materials.push(taskMaterial);
      this.editMaterial(taskMaterial);
    },
    removeMaterial(taskMaterial) {
      this.taskData.materials = this.taskData.materials.filter(
        m => m !== taskMaterial
      );
      this.materialDialogVisible = false;
    },
    editMaterial(taskMaterial) {
      this.materialDialogVisible = true;
      this.selectedTaskMaterial = taskMaterial;
    },
    getParameterName(operationId) {
      const operation = this.operationsById[operationId];
      return operation.data.parameterName || "Parametru";
    },
    async uploadImage() {
      const task = this.taskData;
      if (this.$refs.image.files && this.$refs.image.files.length !== 0) { // hf raul
        const file = this.$refs.image.files[0];
        const path = `tasks/${task.id}/${file.name}`;
        task.imageUrl = await this.$store.uploadFile(file, path);
      }
    },
    async removeImage() {
      const task = this.taskData;
      this.$store.deleteFileByUrl(task.imageUrl);
      task.imageUrl = null;
    },
    getTaskOperationPrice(taskOperation) {
      try {
        return getTaskOperationPrice(
          taskOperation,
          this.context,
          this.operationsById
        );
      } catch {
        return "???";
      }
    },
    getTaskMaterialPrice(taskMaterial) {
      try {
        return getTaskMaterialPrice(
          taskMaterial,
          this.context,
          this.materialsById
        );
      } catch {
        return "???";
      }
    },
    syncOperationName() {
      if (this.taskData.name !== "???") return;
      if (this.taskData.operations.length !== 1) return;

      const { operationId } = this.taskData.operations[0];
      this.taskData.name = this.operationsById[operationId].data.name;
    }
  }
};
</script>
