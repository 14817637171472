<template>
  <ElDialog :visible.sync="visible" title="Pret">
    <template v-if="visible">
      <div class="flex items-center mb-2">
        <label class="block w-24">Adaos</label>
        <ElSelect v-model="project.margin" class="flex-1">
          <ElOption :value="10" label="10%" />
          <ElOption :value="12" label="12%" />
          <ElOption :value="15" label="15%" />
          <ElOption :value="18" label="18%" />
          <ElOption :value="20" label="20%" />
        </ElSelect>
      </div>
      <div class="flex items-center mb-2">
        <label class="block w-24">Pret</label>
        <ElButton @click="updatePrice" icon="el-icon-refresh" class="mr-5" />
        <div class="mr-5 font-bold" v-if="project.price">
          {{ project.price | number }} lei
        </div>
        <div v-if="project.price">
          {{ unitPrice | number }}
          lei / buc
        </div>
      </div>
    </template>
  </ElDialog>
</template>

<script>
import { sumBy } from "lodash";
import { getTaskPrice } from "@/calculator";

export default {
  props: ["project", "context", "database"],
  data() {
    return {
      visible: false
    };
  },
  computed: {
    unitPrice() {
      return this.project.price / this.project.variables.quantity;
    }
  },
  methods: {
    open() {
      this.visible = true;
    },
    async updatePrice() {
      const { margin, tasks } = this.project;
      const basePrice = sumBy(Object.values(tasks), task =>
        getTaskPrice(task, this.context, this.database)
      );
      this.project.price = basePrice * (1 + margin / 100);
      // this.$emit("save");
    }
  }
};
</script>
