<template>
  <div
    class="bg-white p-5 overflow-y-auto flex-shrink-0 border-r border-gray-400"
    style="width: 400px;"
  >
    <div class="text-lg font-bold mb-5">Lucrarea #{{ project.number }}</div>
    <!-- name -->
    <div class="flex items-center mb-2">
      <label class="block w-24">Nume</label>
      <ElInput v-model.number="project.name" class="flex-1" />
    </div>
    <div class="flex items-center mb-2">
      <div class="block w-24"></div>
      <div class="flex-1">
        <el-checkbox
          label="face parte din grup"
          v-model="project.hasgroup"
        ></el-checkbox>
        <el-input
          class="mx-5"
          style="width:150px"
          v-if="project.hasgroup"
          placeholder="id grup"
          v-model.number="project.groupid"
        ></el-input>
      </div>
    </div>
    <!-- client -->
    <div class="flex items-center mb-2">
      <label class="block w-24">Client</label>
      <div class="flex-1">
        <ResourceSelect
          v-model="project.clientId"
          collection-name="clients"
          placeholder="Selecteaza"
          class="w-full mb-2"
        />
        <ElButton size="mini" @click="$refs.clientDialog.add()"
          >Adauga Client</ElButton
        >
      </div>
    </div>
    <!-- descriptions -->
    <div class="flex items-center mb-2">
      <label class="block w-24">Descriere</label>
      <ElInput
        v-model="project.description"
        type="textarea"
        :autosize="{ minRows: 4 }"
        class="flex-1"
      />
    </div>
    <div class="flex items-center mb-2">
      <label class="block w-24">Termen limita</label>
      <el-date-picker
        v-model="project.dueDate"
        type="datetime"
        format="dd MMM yyyy"
        value-format="timestamp"
        placeholder="Select date and time"
        default-time="12:00:00"
      >
      </el-date-picker>
    </div>
    <!-- FSC -->
    <div class="flex items-center mb-2 h-10">
      <label class="block w-24">FSC</label>
      <ElSwitch v-model="project.fsc" />
    </div>
    <!-- image -->
    <div class="flex items-center mb-10">
      <label class="block w-24">Imagine</label>
      <div class="flex-1">
        <a :href="project.imageUrl" target="_blank" v-if="project.imageUrl">
          <img
            class="max-w-full max-h-64"
            style="max-height: 200px"
            :src="project.imageUrl"
        /></a>
        <ElButton v-if="project.imageUrl" @click="removeImage" class="mt-2"
          >Sterge Imaginea</ElButton
        >
        <ElButton v-else class="relative"
          >Ataseaza Imaginea
          <input
            class="absolute opacity-0 top-0 left-0 w-full h-full cursor-pointer"
            type="file"
            accept="image/*"
            ref="image"
            @change="uploadImage"
          />
        </ElButton>
      </div>
    </div>
    <!-- quantity -->
    <div class="flex items-center mb-2">
      <label class="block w-24">Tiraj</label>
      <ElInput
        @blur="setPrice"
        @focus="hideUnitPrice = true"
        class="flex-1"
        v-model.number="project.variables.quantity"
      />
    </div>
    <div class="flex items-center mb-2">
      <label class="block w-24">Pret generat:</label>
      <label v-show="!hideUnitPrice">{{ unitPrice | number }} lei / buc</label>
    </div>
    <div class="flex items-center mb-2 mt-5">
      <label class="block w-24">Tva</label>
      <div class="ml-4">
        <el-checkbox v-model="project.priceWithTva"
          >tva inclus in pret / buc</el-checkbox
        >
      </div>
    </div>
    <div class="flex items-center mb-2">
      <label class="block w-24">Pret fix / buc</label>
      <el-input-number
        class="flex-1"
        v-model.number="project.fixPrice"
        :precision="4"
        :step="0.1"
      ></el-input-number>
    </div>

    <div class="flex items-center mb-2">
      <label class="block w-24">Achitat</label>
      <ElInput class="flex-1" type="number" v-model.number="project.payed" />
    </div>
    <!-- <div v-if="project.finalQuantity" class="flex items-center mb-2">
      <label class="flex-none mr-5">Cantitate produsa</label>
      <ElInput v-model.number="project.finalQuantity" class="flex-1" />
    </div> -->
    <!-- surplus -->
    <!-- <div class="flex items-center mb-2">
      <label class="block w-24">Prisoase</label>
      <ElInput
        v-model.number="project.variables.surplus_rise"
        class="flex-1 mr-2"
      />
      <ElInput v-model.number="project.variables.surplus_base" class="flex-1" />
    </div> -->
    <!-- format -->
    <div class="flex items-center mb-10">
      <label class="block w-24">Format</label>
      <ElInput v-model.number="project.variables.width" class="flex-1 mr-2" />
      <ElInput v-model.number="project.variables.height" class="flex-1 mr-2" />
      <ElButton @click="$refs.formatsDialog.open()" icon="el-icon-files" />
    </div>
    <!-- variables -->
    <div>
      <b>Variabile</b>
      <ElButton
        @click="addVariable"
        icon="el-icon-plus"
        type="text"
        class="ml-2"
      />
    </div>
    <div class="flex items-center mb-2" v-for="key in variableKeys" :key="key">
      <label class="block w-24">{{ key }}</label>
      <ElInput v-model.number="project.variables[key]" class="flex-1 mr-2" />
      <ElButton @click="removeVariable(key)" icon="el-icon-delete" />
    </div>
    <!-- dialogs -->
    <FormatsDialog ref="formatsDialog" @select="setFormat" />
    <ClientDialog
      ref="clientDialog"
      @insert="(clientRef) => $set(project, 'clientId', clientRef.id)"
    />
  </div>
</template>

<script>
import ClientDialog from "@/components/clients/ClientDialog"
import FormatsDialog from "@/components/utils/FormatsDialog"

export default {
  props: ["project", "projectId"],
  data() {
    return {
      hideUnitPrice: false,
    }
  },
  components: {
    ClientDialog,
    FormatsDialog,
  },
  computed: {
    unitPrice() {
      return this.project.price / this.project.variables.quantity
    },
    variableKeys() {
      const omitted = [
        "quantity",
        "width",
        "height",
        "surplus_rise",
        "surplus_base",
      ]
      return Object.keys(this.project.variables).filter(
        (key) => !omitted.includes(key)
      )
    },
  },
  methods: {
    setPrice() {
      this.$emit("updatePrice")
      this.hideUnitPrice = false
    },
    addVariable() {
      const key = prompt("Nume").replace(/[^a-zA-Z0-9_]+/g, "")
      this.$set(this.project.variables, key, "")
    },
    removeVariable(key) {
      this.$delete(this.project.variables, key)
    },
    setFormat({ width, height }) {
      this.project.variables.width = width
      this.project.variables.height = height
    },
    async uploadImage() {
      const file = this.$refs.image.files[0]
      const path = `projects/${this.projectId}/${file.name}`
      this.project.imageUrl = await this.$store.uploadFile(file, path)
    },
    async removeImage() {
      this.$store.deleteFileByUrl(this.project.imageUrl)
      this.project.imageUrl = null
    },
  },
}
</script>
